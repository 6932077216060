/** @jsx jsx */
import { jsx, Flex, Text, Container, AspectRatio } from 'theme-ui';
import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/seo';
import { BlockContent } from '../components/block-content';
import GraphQLErrorList from '../components/graphql-error-list';
import { Stack, Row, Grid, Col } from '../components/grid';
import { Anchor } from '../components/link';
import { Twitter, Facebook, Insta, LinkedIn } from '../icons';
import { Helmet } from 'react-helmet';
import { PromoBanner } from '../components/promo-banner';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        gap: [14, 14, 14],
        paddingTop: [6, 8],
        paddingBottom: [10, 11, 12, 12, 13],
      }}
    >
      <Flex sx={{ flexDirection: 'column', gap: 8 }}>
        <Text variant="h1">Page not found</Text>
        <Text>Looks like you visited a url that doesn't exist. </Text>
      </Flex>
      <Link to="/" sx={{ variant: 'buttons.primary_small' }}>
        Browse our catalog
      </Link>
    </Container>
  </>
);

export default NotFoundPage;
